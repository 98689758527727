do ->
  __isIE8 = false

  fnDetectLegacyIE = (global)->
    'use strict'
    if /MSIE [78]\.0/.test(global.navigator.userAgent)
      $('body').addClass('legacyIE')
      __isIE8 = true



  fnDetectIE = (global)->
    'use strict'
    match = global.navigator.userAgent.match(/MSIE (\d+)\.0/)
    if match && match[1]
      $('body').addClass('IE' + match[1])
      __isIE8 = true if match[1] == '8'



  fnProposalTip = (selector)->
    'use strict'
    klass  = '.js-proposal-pop-up'
    klass2 = '.js-proposal-pop-vail'

    $(selector).hover ->
      $tip  = $(this).find(klass)
      $vail = $(this).find(klass2)
      if __isIE8
        $tip.show()
        $vail.show()
      else
        $tip.stop(true, false).delay(150).fadeIn(240, 'easeOutQuart')
        $vail.stop(true, false).fadeIn(240, 'easeOutQuart')
    , ->
      $tip  = $(this).find(klass)
      $vail = $(this).find(klass2)
      if __isIE8
        $tip.hide()
        $vail.hide()
      else
        $tip.stop(true, false).fadeOut(80)
        $vail.stop(true, false).fadeOut(80)



  fnEntryIcons = (selector, selector2) ->
    'use strict'
    $(selector).hover ->
      $(this).find(selector2).stop(true, false).fadeIn(100)
    , ->
      $(this).find(selector2).stop(true, false).fadeOut(100)




  fnMainVisualAutoHeight = (base, target, ratio, min, max, fps)->
    'use strict'
    $base = $(base)
    $target = $(target)
    $container = $target.find('.main-visual-container')
    $purpose = $target.find('.main-visual-purpose')
    $image   = $container.find('img')

#    return false if $base.length == 0 or $target.length == 0 or $purpose.length == 0
    return false if $base.length == 0

    _ratio = ratio || 0.8
    _min = min || 350
    _max = max || 500
    _fps = fps || 30

    _prevHeight = 0
    _prevMargin = if $purpose.length == 0 then 0 else ~~($purpose.css('margin-bottom').replace('px', ''))

    setInterval ->
      _baseHeight = $base.outerHeight()
      return false if _prevHeight == _baseHeight

      _prevHeight = _baseHeight
      _targetHeight = ~~(_baseHeight * _ratio)

      _targetHeight = if _targetHeight < _min then _min else _targetHeight
      _targetHeight = if _max < _targetHeight then _max else _targetHeight

      # IMPROVE: ちゃんとした割り当てロジックを実装する
      if _targetHeight <= _min + 20
        # 小さい時
        _imageMargin = -10
        _purposeMargin = _prevMargin - 30
      else if _targetHeight >= _max - 20
        # 大きい時
        _imageMargin = 0
      else
        # 中間
        _imageMargin = -5
        _purposeMargin = _prevMargin - 5

      $image.css('margin-top', _imageMargin)
      $purpose.css('margin-bottom', _purposeMargin) unless $purpose.length == 0
      $target.height(_targetHeight)
      $container.height(_targetHeight)
    , 1000 / _fps



  fnCampaignVisualAutoHeight = (base, target, ratio, min, max, fps)->
    'use strict'
    $base = $(base)
    $target = $(target)
    $container = $target.find('.main-visual-container')
    $image = $container.find('img')
    fixAt = $container.data('fix-at')

    # NOTE: 幅固定する仕様追加
    $target.css({maxWidth: "1440px", margin: "0 auto"})

    return false if $base.length == 0

    _ratio = ratio || 0.8
    _min = min || 422
    _max = max || 550
    _fps = fps || 30

    _prevHeight  = 0
    _imageMargin = 0

    setInterval ->
      _baseHeight = $base.outerHeight()
      return false if _prevHeight == _baseHeight

      _prevHeight = _baseHeight
      _targetHeight = ~~(_baseHeight * _ratio)
      _targetHeight = if _targetHeight < _min then _min else _targetHeight
      _targetHeight = if _max < _targetHeight then _max else _targetHeight
      $target.height(_targetHeight)
      #$container.height(_targetHeight)

      if fixAt == 'top'
        _imageMargin = (_max - _targetHeight) * -1
      else if fixAt == 'mid'
        _imageMargin = (_max - _targetHeight) / 2 * -1
      else if fixAt == 'mid1'
        _imageMargin = (_max - _targetHeight) / 2 * -1
        _offset = ~~((1 - _targetHeight / _max) * 5)
        _imageMargin -= _offset
      else if fixAt == 'mid2'
        _imageMargin = (_max - _targetHeight) / 2 * -1
      else
        # do nothing...

      $image.css('margin-top', _imageMargin)
    , 1000 / _fps



  fnSlideVisualAutoHeight = (base, target, ratio, min, max, fps)->
    'use strict'
    $base   = $(base)
    $target = $(target)

    return false unless $base or $target

    _ratio  = ratio || 0.8
    _min = min || 370
    _max = max || 550
    _fps = fps || 30

    _prevHeight = 0

    setInterval ->
      _baseHeight = $base.outerHeight()
      return false if _prevHeight == _baseHeight

      _prevHeight = _baseHeight
      _targetHeight = ~~(_baseHeight * _ratio)
      _targetHeight = if _targetHeight < _min then _min else _targetHeight
      _targetHeight = if _max < _targetHeight then _max else _targetHeight

      $target.height(_targetHeight)
    , 1000 / _fps


  fnSlideVisualScroll = (selector, fps, speed)->
    'use strict'
    $mainVisual = $(selector)

    return false if $mainVisual.length == 0

    $mainVisualContainer = $mainVisual.find('.slide-visual-container')

    $mainVisualContainer.append($mainVisualContainer.html())
    $mainVisualImages = $mainVisualContainer.find('img')

    _fps   = fps   || 24
    _speed = speed || 1
    _x = 0

    setInterval ->
      _w = $mainVisualImages[0].offsetWidth
      if Math.abs(_x) > _w
        _x = 0
        _$first = $mainVisualContainer.find('img').slice(0, 1)
        $mainVisualContainer.append(_$first)

      $mainVisualContainer[0].style.marginLeft = (_x -= _speed) + 'px'
    , (1000 / _fps)


  fnCampaignVisualAdjust = (selector) ->
    'use strict'
    $root = $(selector)
    $img  = $root.find('img')

    return false if $root.length == 0 || $img.length == 0

    # TODO: onloadが発生しない…？
    #$img.one 'load', ->
    #  $img.fadeIn()

    setTimeout ->
      $img.fadeIn()
    , 450

    setInterval ->
      _offset = ($img.outerWidth() - $root.outerWidth()) / 2
      $img.css({ marginLeft: _offset * -1 })
    , (1000 / 30)


  fnShitajiki = (selector, opacity) ->
    'use strict'
    container = document.querySelector(selector)
    return false unless container

    unless opacity == 0
      opacity = opacity || 0.5

    style = container.style

    imgPath = container.getAttribute('data-image')
    img = new Image()
    img.src = imgPath
    img.onload = ->
      style.background = 'url("' + imgPath + '") no-repeat top center'
      style.position = 'absolute'
      style.top     = 0
      style.zIndex  = -9999
      style.opacity = opacity
      style.width   = '100%'
      style.height  = this.height + 'px'
      style.margin  = 'auto'
      info = document.createElement('div')
      info.style.cssText = 'position:fixed;bottom:0;left:0;width:100%;text-align:center;padding:8px 0;color:#fff;background:#666;opacity:.7'
      info.innerText = 'したじき'
      container.appendChild(info)

    return false unless document.addEventListener

    document.addEventListener 'keyup', (e)->
      if e.shiftKey && e.ctrlKey
        opa = parseFloat(style.opacity)
        if e.keyCode == 187
          change = 0.1
        else if e.keyCode == 189
          change = -0.1
        else if e.keyCode == 81
          change = if (opa > 0) then -1 * opa else 1
        else if e.keyCode == 87
          style.zIndex = if style.zIndex == '9999' then -9999 else 9999
        else
          return false
        val = opa + change
        if 0 <= val && val <= 1
          style.opacity = val


  fnSlidePreload = ->
    'use strict'
    setTimeout ->
      mainImgs = []
      $slideImgs  = $('.slide-visual-container > img')
      slideLength = $slideImgs.size()

      loopImageLoader = (num) ->
        mainImgs[num] = new Image()
        mainImgs[num].onload = ->
          $slideImgs.eq(num)
            .addClass('show')
            .stop(true, false)
            .animate({'opacity':1}, 300)
          setTimeout ->
            num++
            if num < slideLength
              loopImageLoader(num)
          , 400
        mainImgs[num].src = $slideImgs.eq(num).attr('src')

      loopImageLoader(0)
    , 800


  fnServiceLighten = (selector)->
    # NOTE: not common...
    iKlass = '.js-service-lighten-image'
    lKlass = '.js-service-lighten-label'
    $(selector).each ->
      _src = ''
      $(this).hover ->
        $el = $(this)
        $el.find(iKlass).animate({opacity: 0.7}, 100)
        $label = $el.find(lKlass)
        _src = $label.attr('src')
        $label.attr('src', _src.replace('.png', '') + '_on.png')
      , ->
        $el = $(this)
        $el.find(iKlass).animate({opacity: 1}, 100)
        $el.find(lKlass).attr('src', _src)



  $ ->
    fnDetectLegacyIE(window)
    fnDetectIE(window)

    fnSlidePreload()
    fnSlideVisualAutoHeight(window, '.js-slide-visual-container', 0.705)
    fnCampaignVisualAutoHeight(window, '.js-campaign-visual', 0.705)
    # NOTE: ロードのタイミング合わせで一瞬ディレイ
    setTimeout ->
      fnSlideVisualScroll('.js-slide-visual', 60)
      fnCampaignVisualAdjust('.js-campaign-visual')
    , 400

    fnMainVisualAutoHeight(window, '.main-visual', 0.65)

    fnProposalTip('.js-proposal-pop')

    fnEntryIcons('.js-entry', '.js-entry-icons')

    fnServiceLighten('.js-service-lighten')

    #fnShitajiki('.js-shitajiki', 0.5)

